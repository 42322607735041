/* eslint-disable react/display-name */
import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import MDXComponents from 'src/components/mdx-components';
import * as css from './drawer-statistics.css';
import * as sys from '../../system';

export default function Statistics({ callouts }) {
  return (
    <MDXProvider components={MDXComponents}>
      <section css={[css.section]} className="reset2">
        <div css={[sys.wrapLast, css.frame, css.grid]}>
          {callouts.length > 0 && callouts.map((callout, index) => {
            return (
              <div key={index} css={css.cell}>
                <h3 css={css.heading}>{callout.heading}</h3>
                <p css={css.numbers}>{callout.preHeading}</p>
                {callout.content.length > 0 && (
                  <p css={css.content}>
                    <MDXRenderer>{callout.contentNode.childMdx.body}</MDXRenderer>
                  </p>
                )}
              </div>
            )
          })}
        </div>
      </section>
    </MDXProvider>
  );
}
