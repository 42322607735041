import { css } from 'styled-components';
export * from './common.css';

export const section = css`
  background-color: #f2f4fa;
  padding: var(--py) 0;
  --py: 36px;
  @media (min-width: 24em) {
    --py: 48px;
  }
  @media (min-width: 36em) {
    --py: 60px;
  }
  @media (min-width: 48em) {
    --py: 72px;
  }
`;

export const frame = css`
  padding: 0 var(--px);
  max-width: 1200px;
  --px: 24px;
  @media (min-width: 24em) {
    --px: 36px;
  }
  @media (min-width: 36em) {
    --px: 48px;
  }
  @media (min-width: 48em) {
    --px: 60px;
  }
`;

export const grid = css`
  --cols: 1;
  display: grid;
  grid-gap: var(--gap-y) var(--gap-x);
  grid-template-rows: max-content;
  grid-template-columns: repeat(var(--cols), minmax(0, 500px));
  justify-content: center;
  text-align: center;
  --gap-y: 24px;
  --gap-x: 24px;
  @media (min-width: 24em) {
    --gap-y: 36px;
    --gap-x: 36px;
  }
  @media (min-width: 36em) {
    --gap-y: 48px;
    --gap-x: 48px;
  }
  @media (min-width: 60em) {
    --cols: 3;
    justify-content: start;
    text-align: left;
  }
`;

export const cell = css``;

export const heading = css`
  margin: 0;
  font-size: var(--fz);
  --fz: 18px;
  @media (min-width: 24em) {
    --fz: 21px;
  }
  @media (min-width: 36em) {
    --fz: 24px;
  }
`;

export const numbers = css`
  margin: 0;
  font-size: var(--fz);
  --fz: 48px;
  font-weight: 700;
  color: #5a16ee;
  @media (min-width: 24em) {
    --fz: 60px;
  }
  @media (min-width: 36em) {
    --fz: 72px;
  }
`;

export const content = css`
  margin: 0;
  color: #707a88;
  font-size: var(--fz);
  line-height: calc(var(--fz) + 8px);
  --fz: 14px;
  @media (min-width: 21em) {
    --fz: 16px;
  }
  @media (min-width: 36em) {
    --fz: 18px;
  }
`;
